// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-components-tablet-view-index-jsx": () => import("../src/components/TabletView/index.jsx" /* webpackChunkName: "component---src-components-tablet-view-index-jsx" */),
  "component---src-components-editor-panel-manage-sections-article-index-jsx": () => import("../src/components/EditorPanel/ManageSections/Article/index.jsx" /* webpackChunkName: "component---src-components-editor-panel-manage-sections-article-index-jsx" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-404-index-jsx": () => import("../src/components/404/index.jsx" /* webpackChunkName: "component---src-components-404-index-jsx" */)
}

